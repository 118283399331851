import styled from "styled-components"

export const SpotlightContainer = styled.div`
text-align: center;
margin-top: 4vh;
align-items: center;
display: flex;
flex-direction: column;
flex: 2;
@media (min-aspect-ratio: 17/20) {
    justify-content: center;
    margin-right: 1.5vh;
    margin-top: 0;
}
@media (max-aspect-ratio: 17/20) {
    margin-right: 1vh;
    margin-left: 1vh;
}
`

export const SpotlightContainer3 = styled.div`
text-align: center;
margin-top: 4vh;
align-items: center;
display: flex;
flex-direction: column;
flex: 2;
@media (min-aspect-ratio: 17/20) {
    justify-content: center;
    margin-right: 1.5vh;
    font-size: 3.5vh;
    margin-top: 0;
}
@media (max-aspect-ratio: 17/20) {
    margin-right: 1vh;
    margin-left: 1vh;
}
`
export const SpotlightChunk = styled.div`
    margin-bottom: 1.7vh;
`

export const SpotlightChunkSmall = styled.div`
    margin-bottom: 1.7vh;
    font-size: 2.3vh;
    width: 70%;
    @media (max-aspect-ratio: 8/5) {
        font-size: 2vh;
    }
    @media (max-aspect-ratio: 17/20) {
        font-size: 1.7vh;
        width: 95%;
    }
`

export const SpotlightChunkMedium = styled.div`
    margin-bottom: 1.7vh;
    font-size: 3.5vh;
    width: 70%;
    @media (max-aspect-ratio: 8/5) {
        font-size: 2vh;
    }
    @media (max-aspect-ratio: 17/20) {
        font-size: 2.1vh;
        width: 92%;
    }
`
export const SpotlightChunkHiring = styled.div`
    margin-bottom: 1.7vh;
    font-size: 3.5vh;
    padding: 2vh;
    width: 50%;
    margin-top: 10%;
    @media (max-aspect-ratio: 8/5) {
        padding: 2vh;
        width: 50%;
        margin-top: 0.1vh;
    }
    @media (max-aspect-ratio: 17/20) {
        font-size: 2.1vh;
        width: 92%;
    }
`

export const SpotlightChunkDesktop = styled.div`
    margin-bottom: 1.7vh;
    @media (max-aspect-ratio: 17/20) {
        display: none;
    }
`

export const SpotlightChunkMobile = styled.div`
    margin-bottom: 1.7vh;
    @media (min-aspect-ratio: 17/20) {
        display: none;
    }
`

export const SpotlightContainer2 = styled.div`
text-align: center;
flex: 3;
margin-top: 3em;
width: 100%;
align-items: center;
display: flex;
flex-direction: column;
@media (min-aspect-ratio: 17/20) {
    justify-content: center;
    margin-top: 0;
    margin-top: 4vh;
}
`

export const SpotlightInfoText = styled.div`
font-size: 2.3vh;
padding: 2vh;
`

export const HorizonalContainer = styled.div`
display: flex;
flex-direction: row;
@media (max-aspect-ratio: 17/20) {
    flex-direction: column;
}
`

export const HorizonalContainerAbout = styled.div`
display: flex;
flex-direction: row;
`

export const TextButton = styled.div`
    margin-right: 1vh;
    margin-left: 1vh;
    margin-top: 3vh;
    text-decoration: underline;
    cursor: pointer;
    @media (max-aspect-ratio: 17/20) {
        font-size: 1.8vh;
        margin-top: 5vh;
        margin-right: 1vh;
        margin-left: 1vh;
    }
`

export const HorizonalContainer3 = styled.div`
display: flex;
flex-direction: row;
width: 45%;
justify-content: space-between;
@media (max-aspect-ratio: 17/20) {
    display: none;
}
`

export const HorizonalContainer2 = styled.div`
display: flex;
flex-direction: row;
justify-content: left;
text-align: left;
@media (max-aspect-ratio: 17/20) {
    flex-direction: column;
}
`

export const InputWrap = styled.div`
display: flex;
width: 90%;
margin-bottom: 1.6vh;
justify-content: center;
${props => props.isDisabled ? "opacity: 0.5;" : ""}
${props => props.isDisabled ? "cursor: default;" : ""}
${props => props.isDisabled ? "pointer-events: none;" : ""}
`

export const BigButton = styled.div`
margin-top: 3.5vh;
margin-right: 2vh;
margin-left: 2vh;
cursor: pointer;
background-color: #FFB30F;
width: 30vh;
height: 10vh;
border-radius: 300px;
color: black;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
filter: drop-shadow(0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25));
${props => props.small && `font-size: 1vh;`}
@media (max-aspect-ratio: 17/20) {
    width: 25vh;
    height: 9vh;
    font-size: 3vh;
}
`

export const BigButtonAbout= styled.div`
margin-top: 1vh;
margin-right: 2vh;
margin-left: 2vh;
cursor: pointer;
background-color: #FFB30F;
width: 30vh;
height: 10vh;
border-radius: 300px;
color: black;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
filter: drop-shadow(0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25));
transition: 0.3s;
@media (max-aspect-ratio: 17/20) {
    width: 25vh;
    height: 9vh;
    font-size: 3vh;
}
@media (max-aspect-ratio: 8/5) {
    width: 20vh;
    height: 6vh;
    font-size: 2.1vh !important;
    margin-top: 0;
    margin-right: 1vh;
    margin-left: 1vh;
}
`

export const Button = styled.div`
${props => props.isDisabled ? "opacity: 0.5;" : ""}
${props => props.isDisabled ? "cursor: default;" : ""}
${props => props.isDisabled ? "pointer-events: none;" : ""}
margin-top: 2vh;
cursor: pointer;
background-color: #FFB30F;
width: 20vh;
height: 7vh;
font-size: 2.7vh;
border-radius: 300px;
color: black;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
filter: drop-shadow(0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25));
transition: 0.3s;
${props => props.small && `font-size: 2vh;`}
`

export const InputLabel = styled.div`
 font-size: 3.1vh;
 text-align: left;
 flex-basis: 28vh;
 flex-shrink: 0;
 ${props => props.focused ? "color: white;" : ""}
 ${props => props.focused ? "text-shadow: 0em 0em 2vh #a49bc6;" : ""}
 @media (max-aspect-ratio: 17/20) {
    font-size: 2.5vh;
    flex-basis: 14vh;
}
`


export const InputLabelSmall = styled.div`
 margin-top: 2vh;
 font-size: 2.2vh;
 white-space: nowrap;
 text-align: left;
 @media (max-aspect-ratio: 17/20) {
    white-space: normal;
    text-align: center;
}
`

export const TextField = styled.input
.attrs((props) => ({
    spellCheck: "false",
    type : props.type || "text",
    autoFocus: props.autoFocus || false,
}))`
border-radius: 5vh;
font-family: Mina, sans-serif;
flex-basis: 50vh;
flex-grow: 0;
font-size: 2.3vh;
outline: none;
border: none;
padding-left: 2vh;
padding-top: 1.3vh;
padding-bottom: 1.3vh;
padding-right: 2vh;
height: 3vh;
background-color: #f1edfc;
-webkit-appearance: none;
box-shadow: 0vh 0.422vh rgba(0, 0, 0, 0.25);
-webkit-appearance: none;
&:focus {
    box-shadow: 0em 0em 1.5em #a49bc6;
    background-color: white;
    ${props => props.warning ?  "box-shadow: 0em 0em 1.5em #ff8e8e" : ""};
}
${props => props.warning ?  "box-shadow: 0em 0em 1.5em #ff8e8e" : ""};
`
export const CheckBox = styled.input
.attrs((props) => ({
    spellCheck: "false",
    type : props.type || "text",
    autoFocus: props.autoFocus || false,
}))`
border-radius: 50%;
font-family: Mina, sans-serif;
width: 3vh;
font-size: 2vh;
margin-top: 2vh;
margin-left: 2vh;
outline: none;
border: none;
padding-left: 2vh;
padding-top: 1.3vh;
padding-bottom: 1.3vh;
padding-right: 2vh;
height: 3vh;
cursor: pointer;
`