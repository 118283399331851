import './App.css';
import Home from "./Home";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { reducer, initialState } from "./reducer"
import React, {useLayoutEffect} from 'react'
import Spotlight from './Spotlight';
import Signup from './Signup';
import Login from './Login';
import ResetRequest from './ResetRequest';
import HiringComponent from './Hiring';
import ContactComponent from './Contact';
import AboutComponent from './About';
import PrivacyComponent from './PrivacyPolicy';
import CalendarComponent from './Calendar';
import EventsComponent from './Events';


export const UserContext = React.createContext({
  state: initialState,
  dispatch: () => null
})

export const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <UserContext.Provider value={[ state, dispatch ]}>
    	{ children }
    </UserContext.Provider>
  )
}

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

function App() {
  return (
  <UserProvider>
  <Router>
    <Wrapper>
    <Home>
     <Routes>
      <Route path="/" element={<Spotlight />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/reset_password" element={<ResetRequest />} />
      <Route path="/hiring" element={<HiringComponent />} />
      <Route path="/calendar" element={<CalendarComponent />} />
      <Route path="/contact" element={<ContactComponent />} />
      <Route path="/about" element={<AboutComponent/>} />
      <Route path="/events" element={<EventsComponent/>} />
      <Route path="/privacy" element={<PrivacyComponent />} />
    </Routes>
    </Home>
    </Wrapper>
  </Router>
  </UserProvider>
  );
}

export default App;
