/* eslint-disable semi */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from 'react';
import './Home.css';
import { SpotlightContainer, BigButton, HorizonalContainer, SpotlightChunk } from './HomePageComponents';
import { useNavigate } from "react-router-dom"
import { UserContext } from './App'
import axios from 'axios';

function HiringComponent() {
  const navigate = useNavigate()
  const [state, dispatch ] = React.useContext(UserContext)
  useEffect(() => {
    if (!state?.messages.hiring) {
      axios.get('https://www.bubblingteapot.com/api/get-all-messages')
      .then((response) => {
        dispatch({type: 'add_messages', messages: response.data})
      })
      .catch((error) => {
      })
    }
  }, [])
  return (
    <SpotlightContainer>
      {
        !!state?.messages?.hiring && state?.messages?.hiring.split('\\').map((item) => <SpotlightChunk>{item}</SpotlightChunk> )
      }
    {/** 
        <SpotlightChunk>
          Work with us!
        </SpotlightChunk>
        <SpotlightChunk>
          Our hiring will officially begin later this month.
        </SpotlightChunk>
        <SpotlightChunk>
          We are looking to hire one additional person.
        </SpotlightChunk>
        <SpotlightChunk>
          More hiring to follow!
        </SpotlightChunk>
        */
    }


     {/** 
      state?.user_obj && (
        <SpotlightChunk>
          In the meantime, learn more about us below and check out our socials!
        </SpotlightChunk>
      )
      */
    }
  


    <HorizonalContainer>
      <a style={{textDecoration: 'none'}}href="https://forms.gle/9azTDCr1ovRuimuu7" target="_blank" rel='noreferrer'>
        <BigButton className='button'>
          Apply
        </BigButton>
      </a>
    </HorizonalContainer>
    </SpotlightContainer>
  );
}
export default HiringComponent;
