/* eslint-disable semi */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './Home.css';
import { SpotlightContainer, BigButtonAbout, HorizonalContainerAbout, SpotlightChunkMedium, SpotlightChunkSmall } from './HomePageComponents';
import { useNavigate } from "react-router-dom"
import { UserContext } from './App'

function AboutComponent() {
  const navigate = useNavigate()
  const [state, dispatch ] = React.useContext(UserContext)
  const [whoWeAre, setWhoWeAre] = React.useState(false)
  return (
    <SpotlightContainer>
    { !whoWeAre && (
      <>
      <SpotlightChunkSmall>
      Welcome to the Bubbling Teapot!
We’re a cozy local cafe with the best bubble tea in all of Madison. Woman and Minority owned.
We pride ourselves in having authentic, high quality teas imported from Taiwan.
We opened in October 2022. Since then we’ve been in the process of improving our shop until it transforms into a cozy, dreamy tea shop out of a fantasy setting. The Bubbling Teapot is a go-to space for the local community that’s inclusive and welcoming to all.

      </SpotlightChunkSmall>
      <SpotlightChunkSmall>
      This shop location was formerly known as Jade Mountain Cafe, and some of our staff (the owner and lead manager) were formerly employed at that business. Our teas, food, and suppliers are still identical to that of Jade Mountain, which was known for its authentic Taiwanese menu. Going forward we will add new food, drinks, and baked goods with a South Asian influence to our menu.
      </SpotlightChunkSmall>
      </>
      )
    }
    { whoWeAre && (
      <>
      <SpotlightChunkSmall>
          Hello! My name is Meena and I’m opening this local tea shop/cafe in Madison!
          I’m a 26 year old woman of South Asian heritage born and raised right here in Madison, WI. 
          This cafe’s location has meant a lot to me as I attended the nearby East High School and worked at Jade Mountain Cafe.
      </SpotlightChunkSmall>
      <SpotlightChunkSmall>
          We also have some of the Jade Mountain Cafe staff moving over to The Bubbling Teapot with us! Most importantly Jessi, a Jade Mountain Cafe pro.
          In our time at the former cafe, we spent much of our time discussing all of the improvements we’d make if we were owners,
          but we assumed these dreams were out of reach financially. Hopefully with us giving it our all and with your support we can make this space a real gem for the community.
      </SpotlightChunkSmall>
      <SpotlightChunkMedium>
          Sign up for updates!
      </SpotlightChunkMedium>
      </>
      )
    }
    <HorizonalContainerAbout>
    {
      !whoWeAre && (
      <BigButtonAbout className='button' style={{fontSize: '3vh'}} onClick={() => setWhoWeAre(true)}>
        Who we are
      </BigButtonAbout>
      )
    }
      <BigButtonAbout className='button' style={{fontSize: '3vh'}} onClick={() => navigate('/signup')}>
        Sign Up!
      </BigButtonAbout>
    </HorizonalContainerAbout>
    </SpotlightContainer>
  );
}
export default AboutComponent;
