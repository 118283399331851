/* eslint-disable semi */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './Home.css';
import { TextField, InputWrap, InputLabel, Button, SpotlightContainer2, InputLabelSmall, CheckBox, SpotlightChunk, HorizonalContainerAbout, TextButton } from './HomePageComponents';
import Axios from 'axios';
import { UserContext } from './App'
import { useNavigate, useSearchParams } from 'react-router-dom'

function LoginComponent() {
  const navigate = useNavigate()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [focusedField, setFocusedField] = React.useState('')
  const [state, dispatch ] = React.useContext(UserContext)
  const [isLoggingIn, setIsLoggingIn] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(false)
  const [signInFailed, setSignInFailed] = React.useState(false)
  const [params] = useSearchParams()
  const showMessage = params?.values()?.next()?.value === 'show'

  const updateEmailPermission = () => {
    setIsUpdating(true)
    const body = {
      emailsAllowed: !state?.user_obj?.emailsAllowed,
    }
    const config = {
      method: 'post',
      url: 'https://www.bubblingteapot.com/api/update-email-permission',
      headers: { 
        'Authorization': `TOKEN ${state?.user_obj?.token}`,
      },
      data : body
    }
    Axios(config).then((res) => {
        dispatch({type: 'log_in', user_obj: { token: state?.user_obj?.token, ...res.data } })
        setIsUpdating(false)
    }).catch(() => console.log("error"))
    }


  const login = () => {
    setIsLoggingIn(true)
    const body = {
      username: email,
      password,
    }
    const data = JSON.stringify(body)
    const config = {
      method: 'post',
      url: 'https://www.bubblingteapot.com/api-token-auth',
      headers: { 
      'Content-Type': 'application/json'
      },
      data : data
    }
    Axios(config).then((res) => {
      const token = res.data.token
      const config2 = {
        method: 'get',
        url: 'https://www.bubblingteapot.com/api/get-details',
        headers: { 
          'Authorization': `TOKEN ${token}`,
        }
      }
      Axios(config2).then((res) => {
        const user_obj = {...res.data, token}
        dispatch({type: 'log_in', user_obj})
        setSignInFailed(false)
        setIsLoggingIn(false)
        navigate('/')
      }).catch((err) => {
        setEmail('')
        setPassword('')
        setSignInFailed(true)
        setIsLoggingIn(false)
      })}).catch((err) => {
        setEmail('')
        setPassword('')
        setIsLoggingIn(false)
        setSignInFailed(true)
      })
  }
  if (state.user_obj) {
    return (
    <SpotlightContainer2>
          <SpotlightChunk style={{paddingLeft: '5vh', paddingRight: '5vh'}}>
              Hello, {state?.user_obj?.username}! <br /> There's not much here for now.
              In the meantime, you can use <br /> the checkbox below to opt in or out of email updates!
          </SpotlightChunk>
        <InputWrap isDisabled={isUpdating}>
         <InputLabelSmall>
          Allow occasional email updates from us? We won't spam you. 💜
         </InputLabelSmall>
        </InputWrap>
        <CheckBox checked={state?.user_obj?.emailsAllowed} type="checkbox" onChange={() => updateEmailPermission()} />

    </SpotlightContainer2>
    )
  }
  return (
          <SpotlightContainer2 style={{marginTop: '8vh'}}>
            {showMessage && <SpotlightChunk style={{fontSize: '3.3vh', marginBottom: '4vh'}}>Thanks for signing up! Log in below.</SpotlightChunk>}
            {signInFailed && <SpotlightChunk style={{fontSize: '3vh', marginBottom: '4vh', color: '#ff8e8e'}}>Email/password invalid! Try Again</SpotlightChunk>}
            <InputWrap>
            <InputLabel focused={focusedField === 'email'}>
              Email
            </InputLabel>
              <TextField
              value={email}
              onFocus={() => setFocusedField('email')} 
              onChange={(e) => {
                setEmail(e.target.value.toLowerCase())
              }}
              onBlur={() => {
                setFocusedField('')
              }} 
              />
            </InputWrap>
            <InputWrap>
            <InputLabel focused={focusedField === 'password'}>
              Password
            </InputLabel>
              <TextField    
                value={password}           
                onBlur={() => {
                  setFocusedField("")
                }} 
                type="password" 
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                onFocus={() => setFocusedField('password')}
                />
            </InputWrap>
            <Button 
              isDisabled={!email || !password || isLoggingIn} 
              className="button"
              onClick={() => login()}
            >
              Login
            </Button>
            <HorizonalContainerAbout style={{marginTop: '2vh', fontSize: '2vh'}}>
              <TextButton onClick={() => navigate('/signup')}>Sign Up!</TextButton><TextButton onClick={() => navigate('/reset_password')}>Forgot Password</TextButton><TextButton onClick={() => navigate('/privacy')}>Privacy Policy</TextButton>
            </HorizonalContainerAbout>
          </SpotlightContainer2>
  )
}
export default LoginComponent;
