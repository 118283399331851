export const reducer = (state, action) => {
  switch (action.type) {
    case "log_in":
      const { user_obj } = action
      return {
        ...state,
        user_obj
      }
      case "add_message":
        const { key, value } = action
        return {
          ...state,
          messages: {
            ...state.messages,
            [key]: value,
          }
      }
      case "add_messages":
        const { messages } = action
        return {
          ...state,
          messages: {
            ...state.messages,
            ...messages,
          }
        }
    default:
      return state
  }
}

export const initialState = {
  user_obj: null,
  messages: {}
}