/* eslint-disable semi */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './Home.css';
import { TextField, InputWrap, InputLabel, Button, SpotlightContainer2 } from './HomePageComponents';
import Axios from 'axios';
import { useNavigate, useSearchParams } from "react-router-dom"

function ResetRequestComponent() {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const token = params?.values()?.next()?.value
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [password2, setPassword2] = React.useState('')
  const [focusedField, setFocusedField] = React.useState('')
  const [requestSent, setRequestSent] = React.useState(false)
  const [warnPassword, setWarnPassword] = React.useState(false)
  const [resetSuccess, setResetSuccess] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const passwordsMatch = password === password2

  const requestReset = () => {
    setIsSubmitting(true)
    const body = {
      email,
    }
    const data = JSON.stringify(body)
    const config = {
      method: 'post',
      url: 'https://www.bubblingteapot.com/api/password_reset/',
      headers: { 
      'Content-Type': 'application/json'
      },
      data : data
    }
    Axios(config).then((res) => {
      setRequestSent(true)
      setIsSubmitting(false)
    }).catch((err) => setRequestSent(true))
  }

  const setNewPassword = () => {
    if (!passwordsMatch) {
      setWarnPassword(true)
      return
    }
    setIsSubmitting(true)
    const body = {
      token,
      password,
    }
    console.log(body)
    const data = JSON.stringify(body)
    const config = {
      method: 'post',
      url: 'https://www.bubblingteapot.com/api/password_reset/confirm/',
      headers: { 
      'Content-Type': 'application/json'
      },
      data : data
    }
    console.log("ABOUT TO REQUEST")
    Axios(config).then((res) => {
      console.log(res)
      setResetSuccess(true)
      setIsSubmitting(false)
    }).catch((err) => {
      console.log('error')
      console.log(err)
    })
  }
  if (token && !resetSuccess) {
    return (
      <SpotlightContainer2 style={{marginTop: '10vh'}}>
            <InputWrap>
            <InputLabel focused={focusedField === 'password'}>
              New Password
            </InputLabel>
              <TextField               
                onBlur={() => {
                  if (!passwordsMatch && password2.length > 0) {
                    setWarnPassword(true)
                  }
                  setFocusedField("")
                }} 
                type="password" 
                onChange={(e) => {
                  setPassword(e.target.value)
                  if (e.target.value === password2) {
                    setWarnPassword(false)
                  }
                }}
                onFocus={() => setFocusedField('password')}
                />
            </InputWrap>
            <InputWrap>
            <InputLabel focused={focusedField === 'password2'}>
              {!warnPassword ? 'Confirm New Password' : <span style={{fontSize: '2.5vh', color: '#ff8e8e'}}>Passwords don't match</span>}
            </InputLabel>
              <TextField 
              warning={warnPassword} 
              type="password"
              onBlur={() => {
                if (!passwordsMatch && password2.length > 0) {
                  setWarnPassword(true)
                }
                setFocusedField("")
              }} 
              onChange={(e) => {
                setPassword2(e.target.value)
                if (e.target.value === password) {
                  setWarnPassword(false)
                }
              }} 
              onFocus={() => setFocusedField('password2')}
              />
            </InputWrap>
            <Button
              small
              isDisabled={!password || !password2 || warnPassword || isSubmitting} 
              className="button" 
              onClick={() => setNewPassword()}
            >
              Submit
            </Button>
        </SpotlightContainer2>
    )
  }
  if (resetSuccess) {
    return (
      <SpotlightContainer2>
      <div style={{fontSize: '2.5vh'}}>Your password has been reset!</div>
      <Button
        small
        className="button" 
        onClick={() => navigate('/login')}
      >
        Go to login
      </Button>
      </SpotlightContainer2>
    )
  }
  return (
          <SpotlightContainer2>
            {
              !requestSent ? (
            <>
            <InputWrap>
            <InputLabel focused={focusedField === 'email'}>
              Email
            </InputLabel>
              <TextField 
              onFocus={() => setFocusedField('email')} 
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              onBlur={() => {
                setFocusedField('')
              }} 
              />
            </InputWrap>
            <Button
              small
              isDisabled={!email || isSubmitting} 
              className="button" 
              onClick={() => requestReset()}
            >
              Reset Password
            </Button>
            </>
              ) : (
              <>
              <div style={{fontSize: '2.5vh'}}>An email with instructions to reset your password has been sent if your email is registered with us.</div>
              <Button
                small
                className="button" 
                onClick={() => navigate('/login')}
              >
                Go to login
              </Button>
              </>
              )
            }
          </SpotlightContainer2>
  )
}
export default ResetRequestComponent;
