/* eslint-disable semi */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useMemo } from 'react';
import './Home.css';
import { SpotlightContainer, TextField, InputWrap, InputLabel, TextButton, HorizonalContainerAbout, InputLabelSmall, Button, CheckBox, HorizonalContainer, SpotlightInfoText, SpotlightContainer2 } from './HomePageComponents';
import Axios from 'axios';
import { useNavigate } from "react-router-dom"

function SignupComponent() {
  const navigate = useNavigate()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [password2, setPassword2] = React.useState('')
  const [warnPassword, setWarnPassword] = React.useState(false)
  const [warnEmail, setWarnEmail] = React.useState(false)
  const passwordsMatch = useMemo(() => password === password2, [password, password2])
  const [focusedField, setFocusedField] = React.useState('')
  const [duplicateEmail, setDuplicateEmail] = React.useState(false)
  const [emailsAllowed, setEmailsAllowed] = React.useState(false)
  const [lengthWarning, setLengthWarning] = React.useState(false)
  
  const validateEmail = useCallback((e) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(e).toLowerCase());
  }, [])

  const signUp = () => {
    setDuplicateEmail(false)
    if (!passwordsMatch) {
      setWarnPassword(true)
      return
    }
    if (!validateEmail(email)) {
      setWarnEmail(true)
      return
    }
    const body = {
      email,
      username: email,
      password,
      password2,
      emailsAllowed: true,
    }
    const data = JSON.stringify(body)
    const config = {
      method: 'post',
      url: 'https://www.bubblingteapot.com/api/register',
      headers: { 
      'Content-Type': 'application/json'
      },
      data : data
    }
    Axios(config).then((res) => {
      navigate('/login?message=show')
    }).catch((err) => {
      if (err.response.data?.email) {
        setDuplicateEmail(email)
        setWarnEmail(true)
      }
    })
  }
  return (
        <HorizonalContainer>
          <SpotlightContainer2>
            <InputWrap>
            <InputLabel focused={focusedField === 'email'}>
            {!warnEmail || email === '' ? 'Email' : <span style={{fontSize: '2.5vh', color: '#ff8e8e'}}>Email {` ${email === duplicateEmail ? 'taken' : 'invalid'}`}</span>}
            </InputLabel>
              <TextField 
              warning={warnEmail && email !== ''}
              autoFocus={false} 
              onFocus={() => setFocusedField('email')} 
              onChange={(e) => {
                setEmail(e.target.value.toLowerCase())
                if (validateEmail(e.target.value)) {
                  setWarnEmail(false)
                }
                if (e.target.value === duplicateEmail) {
                  setWarnEmail(true)
                }
              }}
              onBlur={() => {
                setFocusedField('')
                if ((!validateEmail(email) && email !== '') || email === duplicateEmail) {
                  setWarnEmail(true)
                } else {
                  setWarnEmail(false)
                }
              }} 
              />
            </InputWrap>
            <InputWrap>
            <InputLabel focused={focusedField === 'password'}>
            {!lengthWarning ? 'Password' : <span style={{fontSize: '2.5vh', color: '#ff8e8e'}}>Must be at least 8 characters</span>}
            </InputLabel>
              <TextField
                warning={lengthWarning}              
                onBlur={() => {
                  if (!passwordsMatch && password2.length > 0) {
                    setWarnPassword(true)
                  }
                  if (password.length < 8) {
                    setLengthWarning(true)
                  }
                  setFocusedField("")
                }} 
                type="password" 
                onChange={(e) => {
                  setPassword(e.target.value)
                  if (e.target.value === password2) {
                    setWarnPassword(false)
                  }
                  if (e.target.value.length >= 8) {
                    setLengthWarning(false)
                  }
                }}
                onFocus={() => setFocusedField('password')}
                />
            </InputWrap>
            <InputWrap>
            <InputLabel focused={focusedField === 'password2'}>
              {!warnPassword ? 'Confirm Password' : <span style={{fontSize: '2.5vh', color: '#ff8e8e'}}>Passwords don't match</span>}
            </InputLabel>
              <TextField 
              warning={warnPassword} 
              type="password"
              onBlur={() => {
                if (!passwordsMatch && password2.length > 0) {
                  setWarnPassword(true)
                }
                setFocusedField("")
              }} 
              onChange={(e) => {
                setPassword2(e.target.value)
                if (e.target.value === password) {
                  setWarnPassword(false)
                }
              }} 
              onFocus={() => setFocusedField('password2')}
              />
            </InputWrap>
            <Button 
              isDisabled={!email || !password || !password2 || warnPassword || warnEmail || lengthWarning} 
              className="button" 
              onClick={() => signUp()}
            >
              Sign Up
            </Button>
          </SpotlightContainer2>
          <SpotlightContainer>
            <SpotlightInfoText>
            Create an account to get updates on our progress!
            In the future, you'll be able to get rewards,
            order online, and more! 
            </SpotlightInfoText>
            <HorizonalContainerAbout>
            <TextButton style={{fontSize: '1.6vh'}} onClick={() => navigate('/login')}>Login</TextButton><TextButton style={{fontSize: '1.6vh'}} onClick={() => navigate('/reset_password')}>Forgot Password</TextButton><TextButton style={{fontSize: '1.6vh'}} onClick={() => navigate('/privacy')}>Privacy Policy</TextButton>
            </HorizonalContainerAbout>

          </SpotlightContainer>
      </HorizonalContainer>
  );
}
export default SignupComponent;
