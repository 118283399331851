/* eslint-disable semi */
/* eslint-disable jsx-a11y/alt-text */
import React, {useCallback, useEffect} from 'react';
import './Home.css';
import { SpotlightContainer, BigButton, HorizonalContainer, SpotlightChunk } from './HomePageComponents';
import { useNavigate } from "react-router-dom"
import { UserContext } from './App'
import axios from 'axios';

function Spotlight() {
  const navigate = useNavigate()
  const [hamburger, setHamburger] = React.useState(false)
  const hamburgerClicked = useCallback(
    async (path) => {
    setHamburger(false)
    setTimeout(() => {
      navigate(path)
    }, 20)
    },
    [navigate, setHamburger]
  )
  const [state, dispatch ] = React.useContext(UserContext)
  useEffect(() => {
    if (!state?.messages.spotlight) {
      axios.get('https://www.bubblingteapot.com/api/get-all-messages')
      .then((response) => {
        dispatch({type: 'add_messages', messages: response.data})
      })
      .catch((error) => {
      })
    }
  }, [])
  return (
    <SpotlightContainer>
      {
        !!state?.messages?.spotlight && state?.messages?.spotlight.split('\\').map((item) => <SpotlightChunk>{item}</SpotlightChunk> )
      }
    <HorizonalContainer>
    <BigButton className='button' onClick={() => window.location.replace('https://squareup.com/gift/MLYNG08KPKG8X/order')}>
      Gift Cards
    </BigButton>
    <BigButton className='button' onClick={() => window.location.replace('https://thebubblingteapot.square.site')}>
      Order Online
    </BigButton>
    </HorizonalContainer>
    </SpotlightContainer>
  );
}
export default Spotlight;
