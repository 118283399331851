/* eslint-disable semi */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from 'react';
import './Home.css';
import { SpotlightContainer, SpotlightChunkHiring, BigButton, HorizonalContainer, SpotlightChunk, SpotlightChunkSmall, SpotlightChunkMedium } from './HomePageComponents';
import { useNavigate } from "react-router-dom"
import { UserContext } from './App'
import axios from 'axios';
import CalendarPNG from './calendar2.jpg'
import { InputLabelSmall } from './HomePageComponents';

function EventsComponent() {
  const navigate = useNavigate()
  return (
    <SpotlightContainer>
      <SpotlightChunk style={{color: '#fdcf6c'}}>
      Saturday, 9/28 10AM-4PM <br/>
      Emerson/Eken Art Walk <br/>
      <br/>
      Our Artists: <br/>
      Tierra y Luna <a href="https://www.tierrayluna.store">Website</a> <a href="https://www.instagram.com/tierrayluna.store">Instagram</a> <br/>
      Ziggy & I <a href="https://linktr.ee/shellilee">Website</a> <a href="https://instagram.com/ziggy.and.i">Instagram</a> <br/>
      <br/>
      Art Walk <a href="https://www.emersonekenarts.org">Website</a> <a href=" https://www.instagram.com/emersonekenarts">Instagram</a> <br/>


      </SpotlightChunk>
    </SpotlightContainer>
  );
}
export default EventsComponent;
