/* eslint-disable semi */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './Home.css';
import { SpotlightContainer, SpotlightChunkSmall } from './HomePageComponents';

function PrivacyComponent() {
return (
<div style={{overflow: 'auto', fontSize: '1.5vh'}}>
<span style={{fontSize: '2vh', textDecoration: 'underline'}}>What we collect and how we use it</span> <br />
Account information. If you sign up for a Bubbling Teapot account, we collect your email, your preference on whether or not to be emailed, and your password. We use your email for login, and for sending you occasional emails with updates about The Bubbling Teapot. We use your email preference to determine if we should email you or not. And a hash of your password is stored so we can authenticate you when you log in. 

<br /><span style={{fontSize: '2vh', textDecoration: 'underline'}}>How we protect your information</span> <br />
We are committed to taking all reasonable precautions to protect your information. For example, we use https to transmit information. We also take various technical security steps such as encryption and proper authentication. However, no data transmission over the internet is completely secure and therefore we cannot guarantee the security of any information you transmit to us, and you do so at your own risk.

<br /><span style={{fontSize: '2vh', textDecoration: 'underline'}}>Your choices</span> <br />

You may opt out of receiving all promotional communications by opting out on your account page. 

You may request that we delete your account. Which would delete your email and your account preferences from our records. 

<br /><span style={{fontSize: '2vh', textDecoration: 'underline'}}>Children</span> <br />

Children under the age of 13 are not allowed to create an account or otherwise use our services. Our services are not directed at children under 13. 

<br /><span style={{fontSize: '2vh', textDecoration: 'underline'}}>Changes to this policy</span> <br />

We will update this privacy policy as more features are added to the site. If we do, we will let you know by updating the date at the top of the policy. We may also notify you via email. We encourage you to review the privacy policy whenever you use our services. By continuing to use our Services after Privacy Policy changes go into effect, you agree to be bound by the revised policy.

<br /><span style={{fontSize: '2vh', textDecoration: 'underline'}}>Contact</span> <br />
Please reach out to meena@thebubblingteapot.com with any questions
</div>
)
}
export default PrivacyComponent;
